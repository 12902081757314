body{
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.outermost-div-otp{
    display: flex;
    width: 100%;    
    max-height: 100%;
    // flex-wrap: wrap;
}

.outer-left-div-otp{
    // width: 720px;
    // height: 760px;
    // padding: 220px 160px 258px;
    // background-color: #fff;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    max-width: 50%;
    // border: 1px solid red;
    // background: #0BDA51;
    // padding: .5em;
}

.logo-otp{
    width: 278px;
    height: 80px;
    flex-grow: 0;
    // margin: 0 122px 10px 0;
    object-fit: contain;
}

#title-opt-test{
    width: 120px;
    height: 30px;
    font-family: Roboto;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;

}

#title-opt{
    width: 120px;
    height: 30px;
    flex-grow: 0;
    margin-top: 30px;
    // margin: 30px 281px 0px 0;
    font-family: Roboto;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    // text-align: left;
    color: #051c57;

}

.login-tabpane-otp{
    // width: 400px;
    width: 95%;
    flex-grow: 0;
    height: 36px;
    margin: 32px 0 10px;
    padding: 8px 224px 8px 12px;
    border-radius: 4px;
    border: solid 1px #e6e7e7;
    background-color: #fff;
    margin-left: 10px;
}

.login-tabpane-submit{
    width:"95%";
    height: "40px";
    flex-grow: "0";
    border-radius:"4px" ;
    background-image: "linear-gradient(to top, #1665d8 98%, #1f6fe5 2%)";
    margin-left: 10px;
}

.login-tabpane-otp::placeholder{
    /* design - width: 164px; */
    width: 170px; 
    height: 20px;
    opacity: 0.4;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #273142;
}


.login-tabpane-otp-received{
    // width: 400px;
    width: 95%;
    height: 36px;
    margin: 0px 0 10px;
    padding: 8px 224px 8px 12px;
    border-radius: 4px;
    border: solid 1px #e6e7e7;
    background-color: #fff;
    margin-left: 10px;
}
.login-tabpane-otp-received::placeholder{
    /* design - width: 164px; */
    width: 170px; 
    height: 20px;
    opacity: 0.4;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #273142;
}

.confirm-otp{
    width: 45px;
    height: 22px;
    flex-grow: 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}


.outer-right-div{
    // width: 50%;
    background-color: #3898fe;
    background-image: url('../assets/img/loginimg.svg');
    background-repeat:no-repeat;
    // height: auto;

    flex: 1;
    display: flex;
    // height: 100%;
    // max-width: 50%;
    flex-wrap: wrap;
    align-items: flex-start;
    height: 100vh;
    background-size: cover;
    background-position: center;
}

@media (max-width: 640px) {
    .outer-right-div {display: none;}

    .outermost-div-otp{
        display: block;
        width: 100%;    
        max-height: 100%;
        // flex-wrap: wrap;
    }
    
    .outer-left-div-otp {
        // display: flex;
        // flex-direction: column;
        // min-height: 50%;
        // max-width: 100%;

        display: flex;
        flex: 0;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100%;
        max-width: 100%;
    }
}
