@import "./variables";
@import "./components";
@import "./utilities";

.float-right {
  float: right;
}

.clearfix {
  overflow: auto;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-not_allowed {
  cursor: not-allowed;
}
.cursor-disabled {
  pointer-events: none;
  cursor: default;
}
.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.load-share-custom {
  .ant-modal-header {
    padding: 24px 24px;
    font-size: 20px;
  }
}

.text-blue {
  color: $DropDownborderActive;
}

.text-bold {
  font-weight: bold;
}
.text-semi-bold {
  font-weight: 500;
}

.ant-table-tbody {
  .ant-table-row:nth-child(even) {
    background-color: #f9f9f9;
  }
}

.inline-block {
  display: inline-block;
}

button.ant-btn.filter-btn.filter-active {
  border: 1px solid #40a9ff;
}

.sendemail {
  width: 140px;
  margin-right: 10px;
  padding: 18px 10px;
}

.status-approve-reject {
  background-color: #eaeaea;
  text-align: center;
  border-radius: 5px;
  color: #000;
  padding: 3px 3px;
}
.status-approve-reject.REJECTED {
  color: #e6492d;
}

$align: stretch, center, space-around, space-between, start, space-evenly, end, flex-end, flex-start;

@each $item in $align {
  .justify-content-#{$item} {
    justify-content: $item;
    -webkit-justify-content: $item;
  }
  .align-items-#{$item} {
    align-items: $item;
    -webkit-align-items: $item;
  }
}

.flex-box {
  display: flex;
  display: -webkit-flex;
}
.flex-column {
  @extend .flex-box;
  flex-direction: column;
}

.no-flex-gap {
  gap: 0px !important;
}

.flex-gap-s {
  gap: 2px;
}

.flex-gap-m {
  gap: 5px;
}

.flex-gap-l {
  gap: 15px;
}

.flex-gap-xl {
  gap: 25px;
}
$flexGrow: 1, 2, 3, 4, 5, 6;

@each $item in $flexGrow {
  .flex-#{$item} {
    flex: $item;
  }
}

.badge-wrapper {
  .ant-badge {
    width: 100%;
  }
}

.ant-table-tag.ant-tag {
  width: 100%;
  padding: 5px;
}

.detail-naviagtion {
  .detail-naviagtion-button {
    .ant-btn,
    .ant-btn:focus,
    .ant-btn:active,
    .ant-btn:hover {
      background: transparent;
      border: none;
      height: auto;
      color: #00000099;
    }
  }
}

.icon-size-header {
  font-size: 50px;
}

.icon-size-xl {
  font-size: 20px;
}
.icon-size-l {
  font-size: 15px;
}

.warning-tag,
.ant-tag.warning-tag {
  background-color: $warning-bg;
  border-color: $warning-border;
  color: $warning-text;
}
.error-tag,
.ant-tag.error-tag {
  background-color: $error-bg;
  border-color: $error-border;
  color: $error-text;
}
.success-tag,
.ant-tag.success-tag {
  background-color: $success-bg;
  border-color: $success-border;
  color: $success-text;
}
.info-tag,
.ant-tag.info-tag {
  background-color: $info-bg;
  border-color: $info-border;
  color: $info-text;
}
.primary-info-tag, .ant-tag.primary-info-tag{
  background-color: $primary-info-bg;
  border-color: $primary-info-border;
  color: $primary-info-text;
}

.secondary-info-tag, .ant-tag.secondary-info-tag{
  background-color: $secondary-info-bg;
  border-color: $secondary-info-border;
  color: $secondary-info-text;
}

.tertiary-info-tag, .ant-tag.tertiary-info-tag{
  background-color: $tertiary-info-bg;
  border-color: $tertiary-info-border;
  color: $tertiary-info-text;
}

.info-color {
  color: $info-text;
}

.info-border {
  border-color: $info-border;
}

.vertical-borders {
  border-top-width: 0px;
  border-bottom-width: 0px;
}

.horizontal-borders {
  border-left-width: 0px;
  border-right-width: 0px;
}

.grey-text {
  color: $grey-text;
}
.light-grey-text {
  color: $light-grey-text;
}

.button-bg-color {
  color: #3b5a98;
}

.error-color {
  color: $error-text;
}

.search-bar {
  @extend .flex-box;
  @extend .align-items-center;
  i {
    font-size: 24px;
    padding: 6px;
    @extend .border-grey;
    border-right: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  input {
    margin-left: -1px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 300px;
    height: 38px;
  }
  input:active,
  input:focus {
    outline: none;
    box-shadow: none;
    border-color: $grey-border;
  }
}

.grey-shadow {
  box-shadow: 0px 0px 15px 2px #c7c7c7;
}
.display-none {
  display: none;
}

.black-border {
  border: 1px solid #d0d0d0;
}

.border-bottom {
  border-width: 0px 0px 1px;
}

.border-right-bottom {
  border-width: 0px 1px 0px 0px;
}

.print-waybill-booking-date {
  position: relative;
}
.print-waybill-booking-date::before {
  content: "";
  display: block;
  border-top: 1px solid #d0d0d0;
  position: absolute;
  width: calc(100% + 10px);
  left: -5px;
  top: -2px;
}

.break-all {
  word-break: break-all;
}
.color-red {
  color: #ff0000;
}
.page-wrapper {
  min-height: calc(100vh - 70px);
}

.popup-buttons-divider {
  border-top: 1px solid lightgrey;
  width: calc(100% + 48px);
  position: relative;
  left: -24px;
  margin-top: 20px;
  padding: 24px 10px 0px;
}

.ant-notification.ant-notification-bottomRight {
  .ant-notification-notice-message {
    word-break: break-all;
  }
}

.red-icon-wrapper svg {
  fill: $error-text;
}
.errorimg {
  width: 24em;
  margin-left: 35px;
  margin-bottom: 35px;
}

.litetext{
  color: #777;
}
